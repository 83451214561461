import React, { Component } from 'react';
import axios from 'axios';
import Reafiliar from '../tabs/Reafiliar';

class Configuracion extends Component {
    constructor(props){
        super(props);
        this.state = {
            current_pass: '',
            new_pass: '',
            confirm_pass: '',
            nombreDisc: '',
            parseadoDisc: 'nom',
            disciplinas: [],
            reafiliar: { status: false, param: '' }
        }
    }
    changeView = (window_tab, state, param) => {
        this.setState({
            [window_tab]: { status: state, param: param }
        })
    }
    getValue = (event)=>{
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    setPass = () =>{
        const url = this.props.server + '/gestor';
        if(this.state.current_pass==='' || this.state.new_pass==='' || this.state.confirm_pass===''){
            window.alert('Faltan datos por rellenar');
        }else{
            if(this.state.new_pass!==this.state.confirm_pass){
                window.alert('Las contraseñas son diferentes')
            }else{
                let data ={
                    pass: this.state.current_pass,
                    newpass1: this.state.new_pass,
                    newpass2: this.state.confirm_pass
                }
                axios.patch(url, data, {withCredentials: true}).then((res)=>{
                    if(res.data.status===200){
                        window.alert('Contraseña modificada con éxito');
                    }
                }).catch(()=>{
                    window.alert('Ha ocurrido un error modificando la contraseña')
                })
            }
        }

    }
    render() {
        let alta = new Date(Date.parse(this.props.data.altadate));
        let baja = new Date(alta.getTime()+1000*60*60*24*365);
        let hoy = new Date();
        let dias = (baja.getTime()-hoy.getTime())/1000/60/60/24;
        
        return (
            <div className="content_window">
                <div className="center_column">
                    {(this.state.reafiliar.status) &&
                        <Reafiliar
                            data={this.props.data} 
                            handler={this.changeView}
                            reload={this.load}
                            server={this.props.server}
                        />
                    }
                    <h1 className="floating_left full_width">Administración</h1>
                    {this.props.data.rol === 'gestor' &&
                        <React.Fragment>
                            {this.props.data.permiso==='reafiliar'&&
                                <div className="tarjeta_configuracion floating_left spacer">
                                
                                    <h2 className="floating_left full_width">Reafiliación de la sede:</h2>
                                    <span onClick={() => { this.changeView('reafiliar', true) }}className="button spacer dark  floating_left">Renovar sede</span>  
                                </div>
                            }
                            <div className="tarjeta_configuracion floating_left spacer">
                                <h2 className="floating_left full_width">Cambiar contraseña:</h2>
                                <h3 className="floating_left full_width spacer">Contraseña actual:</h3>
                                <div className="tab_input_container">
                                    <input type='password' onChange={this.getValue} name='current_pass' className="tab_input" />
                                </div>
                                <h3 className="floating_left full_width">Nueva contraseña:</h3>
                                <div className="tab_input_container full_width">
                                    <input type='password' onChange={this.getValue} name='new_pass' className="tab_input" />
                                </div>
                                <h3 className="floating_left full_width">Confirmar nueva contraseña:</h3>
                                <div className="tab_input_container full_width">
                                <input type='password' onChange={this.getValue} name='confirm_pass' className="tab_input" />
                                </div>
                                <div className="full_width floating_left"><span onClick={()=>{this.setPass()}} className="button floating_left dark spacer">Cambiar</span></div>
                            </div>
                            <div className="full_width floating_left spacer"></div>
                            
                        </React.Fragment>
                    }
                    {this.props.data.rol === 'administrador' &&
                        <React.Fragment>
                            <div className="tarjeta_configuracion floating_left spacer">
                                <h2 className="floating_left full_width">Añadir una disciplina:</h2>
                                <h3 className="floating_left full_width spacer">Nombre:</h3>
                                <div className="tab_input_container full_width">
                                <input type='text' onChange={this.getValue} name='nombreDisc' className="tab_input" />
                                </div>
                                
                                <div className="full_width floating_left"><span onClick={()=>{this.insertDisc()}} className="button dark floating_left">Añadir</span></div>
                                
                                <h3 className="floating_left full_width spacer">Todas las disciplinas:</h3>
                                <div className="tab_input_container full_width">
                                {this.state.disciplinas.map((disciplina)=>{
                                    return (
                                        <span style={{margin: '.1rem'}} className="tab_input">{disciplina.nombre}</span>
                                    )
                                })}
                                </div>
                                
                            </div>
                            <div className="tarjeta_configuracion floating_left spacer">
                            <h2 className="floating_left full_width">Configurar precios:</h2>  
                            </div>
                            
                            <div className="full_width floating_left spacer"></div>
                            
                        </React.Fragment>       
                    }
                    
                </div>
                
            </div>
        );
    }
    insertDisc = ()=>{
        if(this.state.nombreDisc === '' || this.state.parseadoDisc === ''){
            window.alert('Faltan datos por rellenar');
        }else{
            const url = this.props.server + '/disciplina';
            axios.post(url,{
                admin: true,
                nombre: this.state.nombreDisc,
                parseado: this.state.parseadoDisc
            }, {withCredentials: true}).then((res)=>{
                if(res.data.status===200){
                    this.load();
                }
            }).catch(()=>{
                window.alert('Error al cargar las disciplinas')
            });
        }
    }
    load = ()=>{
        if(this.props.data.rol === 'administrador'){
            const url = this.props.server + '/disciplina';
            axios.put(url,{admin: true}, {withCredentials: true}).then((res)=>{
                if(res.data.status===200){
                    this.setState({
                        disciplinas: res.data.disciplinas
                    })
                }
            }).catch(()=>{
                window.alert('Error al cargar las disciplinas')
            });
        }
    }
    componentDidMount(){
        this.load();
    }
}

export default Configuracion;