import React, { Component } from 'react';

class JuezCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            desplegado: false
        }
    }
    change = () => {
        this.setState({
            desplegado: !this.state.desplegado
        });
    }
    render() {

        return (
            <div className="tarjeta_s_container">
                <div className="tarjeta_s">
                    <span className="tarjeta_s_licencia"><b>{this.props.juez.licencia}</b></span>
                    <span className="tarjeta_s_nombre">{this.props.juez.nombre} <span style={{color: 'red'}}>CADUCADO</span></span>
                    <a onClick={this.change} className="button tarjeta_s_button">{(this.state.desplegado?'ocultar':'ver')}</a>
                </div>
                <div className={"tarjeta_s_footer spaced"+(this.state.desplegado?" see":" unsee")}>
                    <span className='tarjeta_s_layer'><b>Tipo: </b>{this.props.juez.modalidad}, {this.props.juez.tipo}</span>
                    <span className='tarjeta_s_layer'><b>Disciplina: </b>{this.props.juez.disciplina}</span>
                    <span className='tarjeta_s_layer'><b>Fecha: </b>{this.props.juez.fecha}</span>
                    
                        <span className='button red left_corner'>Eliminar</span>
                        <span className='button dark right_corner'>Reciclar</span>
                    
                </div>
            </div>

        );
    }
}

export default JuezCard;