import React, { Component } from 'react';
import axios from 'axios';

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            precarga: false,
            licencia: '',
            name: '',
            surname: '',
            dni: '',
            nacimiento: '',
            activebefore: '',
            sexo: 'Masculino',
            error: false,
            sendDisciplinas: [],
            precio: 0,
            disciplinas: this.props.data.disciplinas
        };
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        }, ()=>{this.recalc()});
        
    }
    checkboxSelect = (key) => {
        let sendDisciplinas = this.state.sendDisciplinas;
        let disciplinas = this.state.disciplinas;
        if (disciplinas[key].selected) {
            sendDisciplinas.pop();
        } else {
            sendDisciplinas.push(disciplinas[key].id);
        }
        disciplinas[key].selected = !disciplinas[key].selected;
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
        }, ()=>{this.recalc()});
        
    }
    send = () => {
        const url = this.props.server + '/federados';
        var date;
        var parameters;
        if(this.state.precarga){
            parameters = {
                licencia: this.state.licencia,
                disciplinas: this.state.sendDisciplinas,
                precio: (this.state.precio/this.state.sendDisciplinas.length)
            }
        }else{
            date = new Date(this.state.nacimiento);
            date = date.getUTCFullYear() + '-' +
            ('00' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
            ('00' + date.getUTCDate()).slice(-2) + ' ' +
            ('00' + date.getUTCHours()).slice(-2) + ':' +
            ('00' + date.getUTCMinutes()).slice(-2) + ':' +
            ('00' + date.getUTCSeconds()).slice(-2);
            parameters= {
                disciplinas: this.state.sendDisciplinas,
                nombre: this.state.name,
                apellidos: this.state.surname,
                sexo: this.state.sexo,
                dni: this.state.dni,
                precio: (this.state.precio/this.state.sendDisciplinas.length),
                nacimiento: date
            }
        }
        if(this.state.precarga && (this.state.licencia==='' || this.state.sendDisciplinas.length===0)){
            window.alert("Faltan datos por completar");
        }else if(!this.state.precarga && (this.state.nacimiento==='' || this.state.name==='' || this.state.surname==='' || this.state.sexo==='' || this.state.dni==='' || this.state.sendDisciplinas.length===0)){
            window.alert("Faltan datos del usuario por completar");
           
        }else{
            axios.post(url, parameters, { withCredentials: true }).then((res) => {
                if (res.data.status === 200) {
                    this.props.reload();
                    this.props.handler('addUser', false);
                    
                } else {
                    this.setState({
                        pageError: { state: true, message: "Error cargando usuarios" }
                    })
                }
            })
                .catch((err) => {
                    this.setState({
                        pageError: { state: true, message: 'Error cargando usuarios' }
                    })
                })
        }
        
            
    }
    precarga = ()=>{
        const url = this.props.server + '/federados';
        const parameters ={
            licencia: this.state.licencia
        }
        axios.put(url, parameters,{withCredentials: true}).then((res)=>{
            if(res.data.status===200){
                let user = res.data.user;
                this.setState({
                    name: user.rname,
                    surname: user.rsurname,
                    nacimiento: user.nacimiento,
                    dni: user.dni,
                    sexo: user.sexo,
                    activebefore: user.activebefore,
                    precarga: true
                },()=>{this.recalc()});
            }
        }).catch((e)=>{
            window.alert("no se ha podido encontrar el usuario");
        });
    }
    recalc=()=>{
        let nacimiento = new Date(this.state.nacimiento);
        let hoy =new Date()
        let edad = hoy.getFullYear() - nacimiento.getFullYear();
        let fijo = this.props.data.precios.alta.fijomenor;
        let variable = this.props.data.precios.alta.variablemenor;
        if(edad >= this.props.data.precios.alta.edadcorte){
            fijo = this.props.data.precios.alta.fijomayor;
            variable = this.props.data.precios.alta.variablemayor;
        }
        /**Distinguir en si es un nuevo alta o el usuario ya existe.*/
        let precio = (variable*this.state.sendDisciplinas.length);
        if(this.state.precarga===false){
            precio = precio + fijo
            
        }else{
            /**Comprobar si ya tiene algun alta activa para quitar el fijo.*/
            if(this.state.activebefore!==true){
                precio = precio + fijo;
            }
        }
        this.setState({
            precio: precio
        }) 
    }
    render() {
        
        return (
            <div id="column_tab" style={{ top: this.state.altura }}>
                <h1 className="tab_header">Crear una nueva inscripción. </h1>

                <div className="tab_input_container">
                    <span className="tab_input_layout">¿Tiene licencia?:</span>
                    <input type='text' onChange={this.getValue} name='licencia' placeholder='Licencia' className="tab_input" />
                    <span onClick={()=>{this.precarga()}} className="button dark tab_input_button">Cargar datos</span>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Nombre:</span>
                    <input disabled={this.state.precarga} defaultValue={this.state.precarga?this.state.name:''} type='text' onChange={this.getValue} name='name' placeholder='Nombre' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Apellidos:</span>
                    <input disabled={this.state.precarga} defaultValue={this.state.precarga?this.state.surname:''} type='text' onChange={this.getValue} name='surname' placeholder='Apellidos' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">DNI:</span>
                    <input disabled={this.state.precarga} defaultValue={this.state.precarga?this.state.dni:''} type='text' onChange={this.getValue} name='dni' placeholder='DNI' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Nacimiento:</span>
                    <input disabled={this.state.precarga} defaultValue={this.state.precarga?this.state.nacimiento:''} type={this.state.precarga?'text':'date'} onChange={this.getValue} name='nacimiento' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Sexo:</span>
                    <select disabled={this.state.precarga} className="tab_input" onChange={this.getValue} name='sexo'>
                        <option>{this.state.precarga?this.state.sexo:'Masculino'}</option>
                        <option>Femenino</option>
                    </select>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Disciplinas:</span>
                    {
                        this.state.disciplinas.map((disciplina, key) => {
                            return (
                                <span className={disciplina.selected ? "tab_input_checkbox on" : "tab_input_checkbox"} onClick={() => { this.checkboxSelect(key); }} key={key}>{disciplina.name}</span>
                            );
                        })
                    }

                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Precio a pagar:</span>
                    <span className="tab_input">{this.state.precio} €</span>
                </div>




                <span className="button red left_corner" onClick={() => { this.props.handler('addUser', false); }}>cancelar</span>
                <span onClick={() => { this.send() }} className='button dark right_corner'>Afiliar</span>

            </div>
        );
    }
    componentDidMount(){
        let altura = window.pageYOffset + 'px';
        this.setState({
            altura: altura
        })
    }

}
export default AddUser;