import React, { Component } from 'react';
import SignMon from '../tabs/SignMon';
import UserCard from '../UserCard';
import axios from 'axios';
import ModMon from '../tabs/ModMon';

class Monitores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchingkey: '',
            notsearching: true,
            monitores: [],
            renderMonitores: [],
            users: [],
            certs: [],
            signMon: { status: false, param: '' },
            modMon: { status: false, param: '' },
        }
        this.getValue = this.getValue.bind(this);
    }
    changeView = (window_tab, state, param) => {
        this.setState({
            [window_tab]: { status: state, param: param }
        })
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.search(event.target.value)
    }
    search = (cadena)=>{
        cadena = cadena.toLowerCase();
        let list = [];
        
        this.state.monitores.map((user)=>{
            
            let name = user.name.toLowerCase();
            let licencia = user.licencia;
            if(name.indexOf(cadena)!==-1 || licencia.toString()===cadena){
                list.push(user)
            }
            
        });
        this.setState({
            renderMonitores: list,
            notsearching: (cadena===''?true:false)
        });
    }
    
    render() {
        return (
            <div className="content_window">

                <div className="center_column">
                    {(this.state.signMon.status) &&
                        <SignMon
                            handler={this.changeView}
                            param={this.state.signMon.param}
                            server={this.props.server}
                            reload={this.load}
                            data={this.props.data}
                        />
                    }
                    {(this.state.modMon.status) &&
                        <ModMon
                            handler={this.changeView}
                            param={this.state.modMon.param}
                            rol={this.props.data.rol}
                            server={this.props.server}
                            reload={this.load}
                            data={this.props.data}
                        />
                    }
                    <div className="full_width floating_left">
                        <input type='text' onChange={this.getValue} name='searchingkey' placeholder='Buscar monitor por licencia o nombre' className="search_input floating_left" />
                    </div>
                    {this.state.notsearching && this.props.data.rol==='gestor' &&
                        <h3 className="full_width spacer floating_left">Usuarios con certificado: {this.state.certs.length}</h3>
                    }
                    {this.state.notsearching && this.props.data.rol==='gestor' &&
                        this.state.certs.map((user, key) => {
                            return (
                                <UserCard
                                    key={key}
                                    user={user}
                                    modifyHandler={this.changeView}
                                    type='certificado'
                                />
                            )
                        })
                    }
                    <h3 className="spacer floating_left">{this.state.notsearching?'Monitores':'Resultado de búsqueda'}: {this.state.renderMonitores.length}</h3>
                    {
                        this.state.renderMonitores.map((user, key) => {
                            
                            return (
                                <UserCard
                                    key={key}
                                    user={user}
                                    modifyHandler={this.changeView}
                                    type='monitor'
                                    rol={this.props.data.rol}
                                    reload={this.load}
                                    server={this.props.server}
                                />
                            )
                            
                        })
                    }
                    <div className="full_width floating_left spacer">
                    </div>
                </div>
            </div>
        );
    }
    load=()=>{
        const url = this.props.server + '/monitor';
        let parameters = {}
        if(this.props.data.rol==='administrador'){
            parameters.admin=true;
        }
        axios.put(url, parameters, {withCredentials: true}).then((res)=>{
            if(res.data.status===200){
                
                this.setState({
                    monitores: res.data.exams,
                    renderMonitores: res.data.exams,
                })
                if(this.props.data.rol==='gestor'){
                    let certificados = [];
                    res.data.certs.map((certificado)=>{
                        let found = false;
                        res.data.exams.map((exam)=>{
                            if(exam.licencia===certificado.licencia){
                                found = true;
                            }
                        });
                        if(!found){
                            certificados.push(certificado)
                        }
                    });
                    this.setState({
                        certs: certificados
                    });
                }
            }
        }).catch(()=>{
            window.alert("Ha ocurrido un problema de conexión");
        })
    }
    componentDidMount() {
        this.load();
    }
}



export default Monitores;