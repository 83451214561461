import React, { Component } from 'react';
import ShowReg from '../tabs/ShowReg';
import mas from '../../assets/img/mas.png'
import NewExam from '../tabs/NewExam';
import ExamCard from '../Examcard';
import axios from 'axios';
import ModExam from '../tabs/ModExam';

class Exams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licencia: '',
            showReg: false,
            newExam: {state: false, param: ''},
            modExam: {state: false, param: ''},
            registros: []

        }
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    changeView = (window_tab, state, param) => {
        this.setState({
            [window_tab]: { status: state, param: param }
        })
    }
    search = ()=>{
        const url = this.props.server + '/examen';
        if(this.state.licencia===''){
            window.alert("Escribe una licencia válida.");
        }else{
            axios.put(url, {licencia: this.state.licencia},{withCredentials:true}).then((res)=>{
                if(res.data.status===200){
                    this.setState({
                        registros: res.data.exams,
                        showReg: true
                    }, ()=>{console.log(this.state)})
                }
            }).catch(()=>{
                window.alert('Ha ocurrido un error.')
            })
        }
    }
    next = (event) => {
        if (event.key === 'Enter') {
            this.search()
        }
    }
    render() {
        return (
            <div className="content_window">
                <div className="center_column">
                    {(this.state.newExam.status) &&
                        <NewExam
                            handler={this.changeView}
                            server={this.props.server}
                            rol={this.props.data.rol}
                        />
                    }
                    {(this.state.modExam.status) &&
                        <ModExam
                            handler={this.changeView}
                            param={this.state.modExam.param}
                            server={this.props.server}
                            reload = {this.load}
                        />
                    }
                    <h3 className="floating_left">Consultar registros:</h3>
                    
                    <input type='text' onChange={this.getValue} onKeyDown={this.next} name='licencia' placeholder="Licencia..." className="search_input spacer" />
                    <span onClick={()=>{this.search()}} className="button floating_right translate">Ver registro</span>
                    
                    {(this.state.showReg) &&
                        <h3 className="floating_left spacer">resultados: {this.state.registros.length}</h3>
                    }
                    {(this.state.showReg) &&
                        this.state.registros.map((registro, key) => {
                                return (
                                    <ExamCard
                                        key={key}
                                        registro={registro}
                                        rol = {this.props.data.rol}
                                        type='validado'
                                        changeView = {this.changeView}

                                    />
                                )
                        
                        })
                    }
                    
                   
                    
                    <img onClick={() => { this.changeView('newExam', true) }} className="float_button" alt="Registrar examen" title="Registrar examen" src={mas} />
                    
                    
                </div>
            </div>
        );
    }
    
    componentDidMount(){
        
    }
}

export default Exams;