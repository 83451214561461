import React, { Component } from 'react';
import axios from 'axios';

class AddClub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenciasede: '',
            licenciafederado: '',
            precargasede: false,
            precargafederado: false,
            namesede: '',
            correosede: '',
            direccionsede: '',
            preciosede: 0,
            preciofederado: 0,
            preciomonitor: 0,
            correomonitor: '',
            name: '',
            surname: '',
            dni: '',
            nacimiento: '',
            activebefore: false,
            sexo: 'Masculino',
            error: false,
            sendDisciplinas: [],
            disciplinas: []
        };
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    checkboxSelect = (key) => {
        let sendDisciplinas = this.state.sendDisciplinas;
        let disciplinas = this.state.disciplinas;
        if (disciplinas[key].selected) {
            sendDisciplinas.pop();
        } else {
            sendDisciplinas.push(disciplinas[key].id);
        }
        disciplinas[key].selected = !disciplinas[key].selected;
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
        },()=>{this.recalc()} );
    }
    recalc = () => {
        let preciosede = 0;
        let preciofederado = 0;
        let preciomonitor = 0;
        let fijo = 0;
        let variable = 0;
        /*Calculo del precio de sede*/
        fijo = this.props.data.precios.sede.fijonuevo;
        if (this.state.precargasede) {
            fijo = this.props.data.precios.sede.fijo;
        }
        variable = this.props.data.precios.sede.disciplina * this.state.sendDisciplinas.length;
        preciosede = fijo + variable;
        /*Calculo del precio de federado*/
        let nacimiento = new Date(this.state.nacimiento);
        let hoy =new Date()
        let edad = hoy.getFullYear() - nacimiento.getFullYear();
        fijo = this.props.data.precios.alta.fijomenor;
        variable = this.props.data.precios.alta.variablemenor;
        if(edad >= this.props.data.precios.alta.edadcorte){
            fijo = this.props.data.precios.alta.fijomayor;
            variable = this.props.data.precios.alta.variablemayor;
        }
        if(this.state.activebefore){
            fijo = 0
        }
        preciofederado = fijo + variable*this.state.sendDisciplinas.length;
        /*Calculo del precio de monitor*/
        preciomonitor = this.state.sendDisciplinas.length*this.props.data.precios.sede.monitor;
        this.setState({
            preciofederado: preciofederado,
            preciomonitor: preciomonitor,
            preciosede: preciosede
        })
    }
    send = () => {

        if (this.state.sendDisciplinas.length === 0 || this.state.namesede === '' || this.state.direccionsede === '' || this.state.correosede === '') {
            window.alert('¡Faltan datos!');
        } else {
            /**Primero creamos la sede o la inscribimos si ya esta creada*/
            let data = {
                admin: true,
                disciplinas: this.state.sendDisciplinas,
                precio: this.state.preciosede / this.state.sendDisciplinas.length
            }
            if (this.state.precargasede) {
                data.licencia = this.state.licenciasede;
            } else {
                data.nombre = this.state.namesede;
                data.correo = this.state.correosede;
                data.direccion = this.state.direccionsede;
            }
            let url = this.props.server + '/sedes';
            axios.post(url, data, { withCredentials: true }).then((res) => {
                if (res.data.status === 200) {
                    /**Creamos el federado y lo inscribimos a la sede, o solo lo inscribimos si ya existe*/

                    let url = this.props.server + '/federados';
                    var date;
                    var parameters = {
                        admin: true,
                        disciplinas: this.state.sendDisciplinas,
                        precio: (this.state.preciofederado / this.state.sendDisciplinas.length),
                        sede: res.data.licencia
                    }
                    if (this.state.precargafederado) {
                        parameters.licencia = this.state.licenciafederado;
                    } else {
                        date = new Date(this.state.nacimiento);
                        date = date.getUTCFullYear() + '-' +
                            ('00' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
                            ('00' + date.getUTCDate()).slice(-2) + ' ' +
                            ('00' + date.getUTCHours()).slice(-2) + ':' +
                            ('00' + date.getUTCMinutes()).slice(-2) + ':' +
                            ('00' + date.getUTCSeconds()).slice(-2);
                        parameters.nombre = this.state.name
                        parameters.apellidos = this.state.surname
                        parameters.sexo = this.state.sexo
                        parameters.dni = this.state.dni
                        parameters.nacimiento = date
                    }
                    axios.post(url, parameters, { withCredentials: true }).then((res2) => {
                        if (res2.data.status === 200) {
                            let parameters2 = {
                                precio: (this.state.preciomonitor / this.state.sendDisciplinas.length),
                                correo: this.state.correomonitor,
                                disciplinas: this.state.sendDisciplinas,
                                federado: this.state.licenciafederado,
                                sede: res.data.licencia,
                                admin: true
                            }
                            let url = this.props.server + '/monitor';
                            axios.post(url, parameters2, { withCredentials: true }).then((res3) => {
                                if (res3.data.status === 200) {
                                    window.alert('Creado con éxito. Puedes iniciar sesión usando el correo: ' + parameters2.correo + ' y la contraseña: fedamt');
                                    this.props.reload();
                                    this.props.handler('addClub', false);
                                }
                            }).catch(() => {
                                window.alert('Ha ocurrido un error creando el monitor.');
                            })
                        }
                    }).catch((err) => {
                        console.log(err);
                        window.alert("El federado no se ha creado correctamente.");
                    })
                }
            }).catch(() => {
                window.alert("La sede no se ha creado correctamente.");
            })
        }
    }
    precargasede = () => {
        if (this.state.licenciasede === '') {
            window.alert("Introduce una licencia de sede valida");
        } else {
            const url = this.props.server + '/sedes';
            const parameters = {
                admin: true,
                licencia: this.state.licenciasede
            }
            axios.put(url, parameters, { withCredentials: true }).then((res) => {
                if (res.data.status === 200) {
                    let sede = res.data.sede;
                    this.setState({
                        namesede: sede.nombre,
                        correosede: sede.correo,
                        direccionsede: sede.direccion,
                        precargasede: true
                    });

                }
            }).catch(() => {

                window.alert("no se ha podido encontrar el usuario");

            });
        }
    }
    precargafederado = () => {
        if (this.state.licenciafederado === '') {

        } else {
            const url = this.props.server + '/federados';
            const parameters = {
                licencia: this.state.licenciafederado
            }
            axios.put(url, parameters, { withCredentials: true }).then((res) => {
                if (res.data.status === 200) {
                    let user = res.data.user;
                    this.setState({
                        name: user.rname,
                        surname: user.rsurname,
                        nacimiento: user.nacimiento,
                        dni: user.dni,
                        sexo: user.sexo,
                        activebefore: user.activebefore,
                        precargafederado: true
                    }, () => { });
                }
            }).catch(() => {
                window.alert("no se ha podido encontrar el usuario");
            });
        }
    }
    render() {

        return (
            <div id="column_tab" style={{ top: this.state.altura }}>
                <h1 className="tab_header">Crear una nueva sede. </h1>
                <h2 className="full_width floating_left spacer">Datos de la sede: </h2>
                <div className="tab_input_container">
                    <span className="tab_input_layout">¿Tiene licencia?:</span>
                    <input type='text' onChange={this.getValue} name='licenciasede' placeholder='Licencia' className="tab_input" />
                    <span onClick={() => { this.precargasede() }} className="button dark tab_input_button">Cargar datos</span>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Nombre:</span>
                    <input disabled={this.state.precargasede} defaultValue={this.state.precargasede ? this.state.namesede : ''} type='text' onChange={this.getValue} name='namesede' placeholder='Sede' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Correo:</span>
                    <input disabled={this.state.precargasede} defaultValue={this.state.precargasede ? this.state.correosede : ''} type='text' onChange={this.getValue} name='correosede' placeholder='Email' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Dirección:</span>
                    <input disabled={this.state.precargasede} defaultValue={this.state.precargasede ? this.state.direccionsede : ''} type='text' onChange={this.getValue} name='direccionsede' placeholder='Dirección' className="tab_input" />
                </div>
                <h2 className="full_width floating_left spacer">Datos del monitor: </h2>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Correo:</span>
                    <input type='text' onChange={this.getValue} name='correomonitor' placeholder='Email' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">¿Tiene licencia?:</span>
                    <input type='text' onChange={this.getValue} name='licenciafederado' placeholder='Licencia' className="tab_input" />
                    <span onClick={() => { this.precargafederado() }} className="button dark tab_input_button">Cargar datos</span>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Nombre:</span>
                    <input disabled={this.state.precargafederado} defaultValue={this.state.precargafederado ? this.state.name : ''} type='text' onChange={this.getValue} name='name' placeholder='Nombre' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Apellidos:</span>
                    <input disabled={this.state.precargafederado} defaultValue={this.state.precargafederado ? this.state.surname : ''} type='text' onChange={this.getValue} name='surname' placeholder='Apellidos' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">DNI:</span>
                    <input disabled={this.state.precargafederado} defaultValue={this.state.precargafederado ? this.state.dni : ''} type='text' onChange={this.getValue} name='dni' placeholder='DNI' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Nacimiento:</span>
                    <input disabled={this.state.precargafederado} defaultValue={this.state.precargafederado ? this.state.nacimiento : ''} type='date' onChange={this.getValue} name='nacimiento' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Sexo:</span>
                    <select disabled={this.state.precargafederado} className="tab_input" onChange={this.getValue} name='sexo'>
                        <option>{this.state.precarga ? this.state.sexo : 'Masculino'}</option>
                        <option>Masculino</option>
                        <option>Femenino</option>
                    </select>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Disciplinas:</span>
                    {
                        this.state.disciplinas.map((disciplina, key) => {
                            return (
                                <span className={disciplina.selected ? "tab_input_checkbox on" : "tab_input_checkbox"} onClick={() => { this.checkboxSelect(key); }} key={key}>{disciplina.name}</span>
                            );
                        })
                    }

                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Precio a pagar:</span>
                    <span className="tab_input">{this.state.preciosede}€</span>
                </div>




                <span className="button red left_corner" onClick={() => { this.props.handler('addClub', false) }}>cancelar</span>
                <span onClick={() => { this.send() }} className='button dark right_corner'>Afiliar</span>
            </div>
        );
    }
    load = () => {
        const url = this.props.server + '/disciplina';
        axios.put(url, { admin: true }, { withCredentials: true }).then((res) => {
            if (res.data.status === 200) {
                let disciplinas = [];
                res.data.disciplinas.map((disciplina) => {
                    disciplinas.push({ name: disciplina.nombre, id: disciplina.id, selected: false })
                })
                this.setState({
                    disciplinas: disciplinas
                })
            }
        }).catch(() => {
            window.alert('Error al cargar las disciplinas')
        });
    }
    componentDidMount() {
        let altura = window.pageYOffset + 'px';
        this.setState({
            altura: altura
        })
        this.load();
    }

}
export default AddClub;