import React, { Component } from 'react';
import JuezCard from '../JuezCard';
import mas from '../../assets/img/mas.png';
import AddJuez from '../tabs/AddJuez';

class Jueces extends Component {
    constructor(props){
        super(props);
        this.state = {
            searchingkey: '',
            notsearching: true,
            jueces: [
                {licencia: 123, nombre: "Alvaro Dominguez Bracho", tipo: 'Juez provincial', disciplina: "Karate", modalidad: 'Katas', fecha:"24/5/2021"},
                {licencia: 123, nombre: "Antonio Dominguez Bracho", tipo: 'Juez provincial', disciplina: "Karate", modalidad: 'Katas', fecha:"24/5/2021"},
                {licencia: 123, nombre: "Alberto Dominguez Bracho", tipo: 'Juez provincial', disciplina: "Karate",modalidad: 'Katas', fecha:"24/5/2021"},
                {licencia: 123, nombre: "Olivia Dominguez Bracho", tipo: 'Juez provincial', disciplina: "Karate",modalidad: 'Katas', fecha:"24/5/2021"},
                {licencia: 123, nombre: "Julio Dominguez Bracho", tipo: 'Juez provincial', disciplina: "Karate",modalidad: 'Katas', fecha:"24/5/2021"},
                 ],
            renderJueces: [
               
            ],
            addJuez: { status: false, param: '' },

        }
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.search(event.target.value)
    }
    changeView = (window_tab, state, param) => {
        this.setState({
            [window_tab]: { status: state, param: param }
        })
    }
    search = (cadena)=>{
        cadena = cadena.toLowerCase();
        let list = [];
        this.state.jueces.map((juez)=>{
            let nombre = juez.nombre.toLowerCase();
            let tipo = juez.tipo.toLowerCase();
            let licencia = juez.licencia;
            let modalidad = juez.modalidad.toLowerCase();
            let disciplina = juez.disciplina.toLowerCase();
            
            if(nombre.indexOf(cadena)!==-1 || disciplina.indexOf(cadena)!==-1 || tipo.indexOf(cadena)!==-1 || licencia===cadena || modalidad.indexOf(cadena)!==-1){
                list.push(juez)
            }
                
            
        });
        if(cadena===""){
            list = this.state.jueces;
        }
        this.setState({
            renderJueces: list,
            notsearching: (cadena===''?true:false)
        });
    }
    render() {
        return (
            <div className="content_window">
                <div className="center_column">
                    {(this.state.addJuez.status) &&
                        <AddJuez
                            handler={this.changeView}
                        />
                    }
                    <h1 onClick={()=>{console.log(this.state)}} className="floating_left">Registro de árbitros y jueces</h1>
                    <div className="full_width spacer floating_left">
                        <input type='text' onChange={this.getValue} name='searchingkey' placeholder='Buscar por licencia, modalidad, disciplina...' className="search_input floating_left" />
                    </div>
                    
                    <h3 className="spacer floating_left">Jueces: {this.state.renderJueces.length}</h3>
                    
                    {
                        this.state.renderJueces.map((juez, key) => {
                            return (
                                <JuezCard
                                    key={key}
                                    juez={juez}
                                />
                            )
                        })
                    }
                </div>
                <img onClick={() => { this.changeView('addJuez', true) }} className="float_button" alt="Crear nuevo usuario" title="Crear nuevo usuario" src={mas} />
            </div>
        );
    }
    componentDidMount(){
        let jueces = this.state.jueces;
        this.setState({
            renderJueces: jueces
        })
    }
}

export default Jueces;