import React, { Component } from 'react';
import mas from '../../assets/img/mas.png';
import AddClub from '../tabs/AddClub';
import ModClub from '../tabs/ModClub';
import ClubCard from '../ClubCard';
import axios from 'axios';

class Clubes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchingkey: '',
            notsearching: true,
            clubes: [],
            validRenderClubes: [],
            validClubes: [],
            addClub: { status: false, param: '' },
            modClub: { status: false, param: '' },
            solForm: { status: false, param: '' }
        }
        this.getValue = this.getValue.bind(this);
    }
    changeView = (window_tab, state, param) => {
        this.setState({
            [window_tab]: { status: state, param: param }
        })
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.search(event.target.value)
    }
    search = (cadena)=>{
        cadena = cadena.toLowerCase();
        let list = [];
        this.state.clubes.map((club)=>{
            let name = club.name.toLowerCase();
            let licencia = club.licencia;
            let dni = club.dni.toLowerCase();
            if(club.validado){
                if(name.indexOf(cadena)!=-1 || licencia==cadena || dni == cadena){
                    list.push(club)
                }
                
            }
        });
        this.setState({
            validRenderClubes: list,
            notsearching: (cadena===''?true:false)
        });
    }


    render() {
        return (
            <div className="content_window">
                <div className="center_column">
                    {(this.state.addClub.status) &&
                        <AddClub
                            data={this.props.data} 
                            handler={this.changeView}
                            reload={this.load}
                            server={this.props.server}
                        />
                    }
                    {(this.state.modClub.status) &&
                        <ModClub
                            handler={this.changeView}
                            param={this.state.modClub.param}
                            reload={this.load}
                            server={this.props.server}
                            data={this.props.data}
                        />
                    }
                    <div className="full_width floating_left">
                        <input type='text' onChange={this.getValue} name='searchingkey' placeholder='Buscar por licencia o nombre del club' className="search_input floating_left" />
                    </div>
                    {this.state.notsearching &&
                        <h3 className="spacer floating_left full_width">Pendientes de validación: {this.state.clubes.length}</h3>
                    }
                     {this.state.notsearching &&
                        this.state.clubes.map((club, key) => {
                            return (
                                <ClubCard
                                    key={key}
                                    club={club}
                                    modifyHandler={this.changeView}
                                    rol={this.props.data.rol}
                                    type='pendiente'
                                    reload={this.load}
                                    server={this.props.server}
                                />
                            )
                        })
                    }
                    <h3 className="spacer floating_left">{this.state.notsearching?'Validados':'Resultado de búsqueda'}: {this.state.validRenderClubes.length}</h3>
                    {
                        this.state.validRenderClubes.map((club, key) => { 
                                return (
                                    <ClubCard
                                        key={key}
                                        club={club}
                                        rol = {this.props.data.rol}
                                        modifyHandler={this.changeView}
                                        type='validado'
                                    />
                                )
                        })
                    }
                </div>
                
                <img onClick={() => { this.changeView('addClub', true) }} className="float_button" alt="Crear nuevo usuario" title="Crear nuevo usuario" src={mas} />
                
                </div>
        );
    }
    load = ()=>{
        const url = this.props.server + '/sedes';
        axios.put(url,{admin:true}, {withCredentials: true}).then((res)=>{
            if(res.data.status===200){
                let valid = [];
                let notvalid = [];
                res.data.sedes.map((sede)=>{
                    if(sede.valido===1 || sede.valido===true){
                        valid.push(sede);
                    }else{
                        notvalid.push(sede);
                    }
                })
                this.setState({
                    clubes: notvalid,
                    validClubes: valid,
                    validRenderClubes: valid
                });
            }
        }).catch(()=>{
            window.alert('Error cargando la página')
        })
        
    }
    componentDidMount() {
        this.load();
    }
}

export default Clubes;