import React, { Component } from 'react';

class Seguridad extends Component {
    render() {
        return (
            <div className="content_window">
                <div className="center_column">
                    <h3>Cambiar contraseña:</h3>
                    <div className="tab_input_container">
                        <span className="tab_input_layout">Contraseña actual:</span>
                        <input type='password' onChange={this.getValue} name='current' className="tab_input" />
                    </div>
                    <div className="tab_input_container">
                        <span className="tab_input_layout">Nueva:</span>
                        <input type='password' onChange={this.getValue} name='new' className="tab_input" />
                    </div>
                    <div className="tab_input_container">
                    <span className="tab_input_layout">Confirmar:</span>
                    <input type='password' onChange={this.getValue} name='confirm' className="tab_input" />
                    </div>
                    <div className="spacer"><span className="button floating_left spacer">Cambiar</span></div>
                </div>
            </div>
        );
    }
}

export default Seguridad;