import React, { Component } from 'react';
import Federados from '../views/Federados';
import Inicio from '../views/Inicio';
import Configuracion from '../views/Configuracion';
import Seguridad from '../views/Seguridad';
import Exams from '../views/Exams';
import Monitores from '../views/Monitores';
import Clubes from '../views/Clubes';
import Jueces from '../views/Jueces';
import Historial from '../views/Historial';

class Content extends Component {
    render() {
        let view;
        switch (this.props.route) {
            case '/inicio':
                view = <Inicio
                    data={this.props.data}
                    server={this.props.server}
                />;
                break;
            case '/federados':
                view = <Federados
                    data={this.props.data}
                    server={this.props.server}
                />;
                break;
            case '/configuracion':
                view = <Configuracion
                    data={this.props.data}
                    server={this.props.server}
                />;
                break
            case '/seguridad':
                view = <Seguridad
                    data={this.props.data}
                    server={this.props.server}
                />;
                break
            case '/examenes':
                view = <Exams
                    data={this.props.data}
                    server={this.props.server}
                />;
                break
            case '/jueces':
                view = <Jueces
                    data={this.props.data}
                    server={this.props.server}
                />;
                break
            case '/monitores':
                view = <Monitores
                    data={this.props.data}
                    server={this.props.server}
                />;
                break
            case '/historial':
            view = <Historial
                data={this.props.data}
                server={this.props.server}
            />;
            break
            case '/clubes':
                view = <Clubes
                    data={this.props.data}
                    server={this.props.server}
                />;
                break
            default:
                break;
        }
        return (
            <div id="content">
                {view}

            </div>
        );
    }
}

export default Content;