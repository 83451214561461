import React, { Component } from 'react';

class MenuClicker extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
             this.props.expandir &&
                <div onClick={()=>{this.props.handler()}} id="menu_clicker"></div>
            
        )
    }
}
export default MenuClicker;