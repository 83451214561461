import React, { Component } from 'react';
import axios from 'axios';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: "",
            pass: "",
            pageError: { state: false, message: '' }
        }
    }
    get = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    log = () => {
        const url = this.props.server + '/log';
        
        axios.post(url, {
            correo: this.state.user,
            pass: this.state.pass
        }, { withCredentials: true }).then((res) => {
            if (res.data.status === 200) {
                let userdata = this.props.data;
                let disciplinas=[];

                res.data.disciplinas.map((disciplina)=>{
                    disciplinas.push({name:disciplina.nombre, id:disciplina.id, selected:false})
                })

                userdata.rol = 'gestor';
                userdata.username = res.data.nombre;
                userdata.permiso = res.data.permiso;
                userdata.disciplinas = disciplinas;
                

                this.props.setUser(userdata)
                this.props.handler('/inicio')
            } else {
                this.setState({
                    pageError: { state: true, message: res.data.message }
                })
            }
        })
        .catch(() => {
            this.setState({
                pageError: { state: true, message: 'Ha ocurrido un error, vuelva a intentarlo.' }
            })
        })
    }
    next = (event) => {
        if (event.key === 'Enter') {
            this.log()
        }
    }
    render() {
        return (
            <div id="logger">
                <div id="logger_column">
                    <span className="logger_title">Iniciar sesión.</span>
                    <input onChange={this.get} onKeyDown={this.next} placeholder="Correo" name="user" className="logger_input" type="text" />
                    <input onChange={this.get} onKeyDown={this.next} placeholder="Contraseña" name="pass" className="logger_input" type="password" />
                    <div onClick={this.log} className="logger_button">Entrar</div>
                    {this.state.pageError.state &&
                        <span style={{ color: '#ff3b00' }}>{this.state.pageError.message}</span>
                    }

                </div>
            </div>
        );
    }
    
    componentDidMount(){
       
    }

}
export default Login;