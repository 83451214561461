import React, { Component } from 'react';


class SolForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            name: '',
            surname: '',
            dni: '',
            nacimiento: '',
            sexo: 'Masculino',
            error: false,
            sendDisciplinas: [],
            disciplinas: []
        };
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    checkboxSelect = (key)=>{
        let sendDisciplinas = this.state.sendDisciplinas;
        let disciplinas = this.state.disciplinas;
        if(disciplinas[key].selected){
            sendDisciplinas.pop();
        }else{
            sendDisciplinas.push(disciplinas[key].name);
        }
        disciplinas[key].selected = !disciplinas[key].selected;
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
        });
    }
    send = ()=>{
        if(this.state.sendDisciplinas.length===0){
            window.alert('No hay disciplinas seleccionadas.');
            this.props.handler('solForm', false); 
        }else{
            window.alert('Ha ocurrido un error inesperado. Para solicitar una formación para '+this.props.param.rname+', contacta con el administrador de la federación.'); 
            this.props.handler('solForm', false); 
        }

    }
    render() {
        let altura=window.pageYOffset+'px';
        let disabled=this.state.disabled?'disabled':'';
        return (
            <div id="column_tab" style={{top: altura}}>
                <h1 className="tab_header">Solicita formación para un federado</h1>
                
                <div className="tab_input_container">
                    <span className="tab_input_layout">Disciplinas:</span>
                    {
                        this.state.disciplinas.map((disciplina, key) => {
                            return (
                                <span className={disciplina.selected?"tab_input_checkbox on":"tab_input_checkbox"} onClick={()=>{this.checkboxSelect(key);}} key={key}>{disciplina.name}</span>
                            );
                        })
                    }

                </div>




                <span className="button red left_corner" onClick={() => { this.props.handler('solForm', false); }}>cancelar</span>
                <span onClick={() => { this.send()}} className='button dark right_corner'>Solicitar</span>

            </div>
        );
    }
    componentDidMount(){
        let disciplinas = [];
        this.props.param.disciplinas.map((disciplina)=>{
            disciplinas.push({name: disciplina.nombre, id: disciplina.id, selected: false})
        });
        this.setState({
            disciplinas: disciplinas
        });
    }

}
export default SolForm;