import React, { Component } from 'react';
import axios from 'axios';


class ExamCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            desplegado: false
        }
    }
    change = () => {
        this.setState({
            desplegado: !this.state.desplegado
        });
    }
    validate=()=>{
        const registro=this.props.registro.id;
        const url=this.props.server+'/examen';
        axios.patch(url, {id: registro, admin:true, validar: true}, {withCredentials:true}).then((res)=>{
            if(res.data.status===200){
                this.change();
                this.props.reload();
            }

        }).catch(()=>{
            window.alert('Ha ocurrido un error.');
        });

    }
    delete=()=>{
        const registro=this.props.registro.id;
        const url=this.props.server+'/examen';
        const parameters = {
            admin: true,
            id: registro
        };
        axios.delete(url, {
            data: parameters,
            withCredentials:true
        }).then((res)=>{
            if(res.data.status===200){
                this.change();
                this.props.reload();
            }

        }).catch(()=>{
            window.alert('Ha ocurrido un error.');
        });

    }

    render() {

        return (
            <div className="tarjeta_s_container">
                <div className="tarjeta_s">
                    <span className="tarjeta_s_licencia"><b>{this.props.registro.licencia}</b></span>
                    <span className="tarjeta_s_nombre">{this.props.registro.disciplina}, {this.props.registro.examen}</span>
                    <a onClick={this.change} className="button tarjeta_s_button">{(this.state.desplegado?'ocultar':'ver')}</a>
                </div>
                <div className={"tarjeta_s_footer"+(this.props.rol==='gestor'?'':' spaced')+(this.state.desplegado?" see":" unsee")}>
                    {this.props.rol ==='administrador' &&
                        <span className='tarjeta_s_layer'><b>Sede: </b>{this.props.registro.sede}</span>
                    }
                    {this.props.rol ==='administrador' &&
                        <span className='tarjeta_s_layer'><b>Precio: </b>{this.props.registro.precio}€</span>
                    }
                    <span className='tarjeta_s_layer'><b>Federado: </b>{this.props.registro.nombre} {this.props.registro.apellidos}</span>
                    <span className='tarjeta_s_layer'><b>Resultado: </b>{this.props.registro.aprobado===1?'Aprobado':'Suspenso'}</span>
                    {(this.props.registro.id!=0) &&
                        <span className='tarjeta_s_layer'><b>Identificador: </b>{this.props.registro.identificador}</span>
                    }
                    <span className='tarjeta_s_layer'><b>Fecha de realización: </b>{this.props.registro.fecha}</span>
                    {this.props.registro.monitores &&
                        <span className='tarjeta_s_layer'><b>Monitores: </b>{this.props.registro.monitores.map((monitor, key)=>{
                            if(key==(this.props.registro.monitores.length-1)){
                                return(monitor.nombre)
                            }else{
                                return(monitor.nombre+', ')
                            }
                            
                            })}</span>
                    }
                    
                    {this.props.rol ==='administrador' && this.props.type === 'validado' &&
                        <span onClick={() => { this.props.changeView('modExam', true, this.props.registro) }} className='button dark right_corner'>Modificar</span>
                    }
                   
                    {this.props.rol ==='administrador' && this.props.type != 'validado' &&
                    
                        <span onClick={()=>{this.validate()}} className='button dark right_corner'>Validar</span>
                    }{this.props.rol ==='administrador' && this.props.type != 'validado' &&
                    
                    <span onClick={()=>{if(window.confirm('¿Eliminar este registro de forma permanente?')){this.delete()}}} className='button red left_corner'>Eliminar</span>
                }
                </div>
            </div>

        );
    }
}

export default ExamCard;