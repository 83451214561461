import './App.css';
import React, { Component } from 'react';
import LateralMenu from './components/menu_components/LateralMenu'
import Header from './components/menu_components/Header';
import Content from './components/menu_components/Content';
import MenuCliker from './components/menu_components/MenuClicker';
import Login from './components/menu_components/Login';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandir: false,
      ruta: '/login',
      server: '/backend',
      userdata: {
        username: '',
        permiso: '',
        rol: '',
        precios: {
          alta: {
            edadcorte: 14.5,
            fijomenor: 15.5,
            fijomayor: 15.5,
            variablemenor: 15.5,
            variablemayor: 20.5,
          },
          sede:{
            fijo: 20,
            fijonuevo: 120,
            monitor: 25,
            disciplina: 35

          },
          monitor: {
            titulo: 210,
            eregional: 350,
            enacional: 495,
          }

        }
      }
    }
  }
  setUser = (userdata)=>{
    this.setState({
      userdata: userdata
    })
  }
  changeRol = ()=>{
    let userdata = this.state.userdata;
    userdata.rol = (userdata.rol==='gestor')?'administrador':'gestor';
    this.setState({
      userdata: userdata,
      ruta: '/inicio'
    })
  }
  expand = () => {
    this.setState({
      expandir: !this.state.expandir
    });
  }
  changeRoute = (ruta) => {
    this.setState({
      ruta: ruta,
      expandir: false
    });
  }
  render() {
    if (this.state.ruta === '/login') {
      return (
        <Login 
          handler={this.changeRoute}
          server={this.state.server}
          setUser={this.setUser}
          data={this.state.userdata}
        />
      )
    } else {
      return (
        <React.Fragment>
          <div id="background"></div>
          <MenuCliker
            expandir={this.state.expandir}
            handler={this.expand}
          />
          <LateralMenu
            expandir={this.state.expandir}
            handler={this.changeRoute}
            rol = {this.changeRol}
            data={this.state.userdata}
          />

          <Header
            handler={this.expand}
            rol = {this.changeRol}
            data={this.state.userdata}
          />
          <Content
            route={this.state.ruta}
            data={this.state.userdata}
            server={this.state.server}
          />
        </React.Fragment>
      );
    }
  }
  componentDidMount(){
  
  }
}

export default App;
