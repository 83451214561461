import React, { Component } from 'react';


class ShowReg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    render() {
        let altura=window.pageYOffset+'px';
        return (
            <div id="column_tab" style={{top: altura}}>
                registro de {this.props.param}
                <span onClick={()=>{this.props.handler('showReg', false)}} className="button right_corner red">Cerrar</span>
            </div>
        );
    }

}
export default ShowReg;