import React, { Component } from 'react';

class HistoryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            desplegado: false
        }
    }
    change = () => {
        this.setState({
            desplegado: !this.state.desplegado
        });
    }
    render() {

        return (
            <div className="tarjeta_s_container">
                <div className="tarjeta_s">
                    <span className="tarjeta_s_licencia"><b>{this.props.usuario.licencia}</b></span>
                    <span className="tarjeta_s_nombre">{this.props.usuario.name}</span>
                    <a onClick={this.change} className="button tarjeta_s_button">{(this.state.desplegado?'ocultar':'ver')}</a>
                </div>
                <div className={"tarjeta_s_footer"+(this.state.desplegado?" see":" unsee")}>
                    <span className='tarjeta_s_layer'><b>DNI: </b>{this.props.usuario.dni}</span>
                    <span className='tarjeta_s_layer'><b>Nacimiento: </b>{this.props.usuario.nacimiento}</span>
                    <span className='tarjeta_s_layer'><b>Historial: </b>
                    {this.props.usuario.historial.map((fecha, key)=>{
                        if(key===this.props.usuario.historial.length - 1){
                            return fecha
                        }else
                            return fecha+', '
                    })
                    }
                    </span>                 
                </div>
            </div>

        );
    }
}

export default HistoryCard;