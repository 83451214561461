import React, { Component } from 'react';

class Inicio extends Component {
    render() {
        return (
            <div className="content_window">
                <h3 className="welcome">Bienvenido,<br/> {this.props.data.username}</h3>
                {this.props.data.permiso==='reafiliar'&&
                    <p style={{width:'100%', textAlign: 'center', fontSize: '2rem', padding:'0 2rem'}}>Su suscripción ha caducado. En configuración podrás reafiliar el club para el próximo año. Si ya has realizado la reafiliación, espera a que sea validada por administración. </p>
                }
            </div>
        );
    }
}

export default Inicio;