import React, { Component } from 'react';
import axios from 'axios';


class ModExam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fecha : ''
        };
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    send =()=>{
        if(this.state.fecha===''){
            window.alert('Selecciona  una nueva fecha.');
        }else{
            const url = this.props.server+'/examen';
            axios.patch(url, {admin: true, id: this.props.param.id, fecha: this.state.fecha}, {withCredentials:true}).then((res)=>{
                if(res.data.status===200){
                    this.props.reload();
                    this.props.handler('modExam', false);
                }
            }).catch(()=>{
                window.alert('Ha ocurrido un error inesperado');
            })
        }

    }
    render() {
        
        return (
            <div id="column_tab" style={{top: this.state.altura}}>
                <h1 className="tab_header">Modificar la fecha del examen de {this.props.param.nombre} </h1>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Nueva Fecha:</span>
                    <input type="date" className="tab_input" defaultValue={this.props.param.fecha} onChange={this.getValue} name='fecha' />

                </div>
                <span onClick={()=>{this.props.handler('modExam', false)}} className="button left_corner red">Cerrar</span>
                <span onClick={()=>{this.send()}} className="button right_corner dark">Modificar</span>
            
            </div>
        );
    }
    componentDidMount(){
        let altura=window.pageYOffset+'px';
        this.setState({
            altura: altura
        });
    }

}
export default ModExam;