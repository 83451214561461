import React, {Component} from 'react';

class LateralMenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            buttons: [
                {name: 'usuarios', route: '/federados', adminpage: false},
                {name: 'examenes', route: '/examenes', adminpage: false},
                {name: 'Monitores', route: '/monitores', adminpage: false},
                {name: 'Configuración', route: '/configuracion', adminpage: false},
                {name: 'Clubes', route: '/clubes', adminpage: true},
                {name: 'Historial', route: '/historial', adminpage: false},
                /*{name: 'Jueces', route: '/jueces', adminpage: true},*/
            ]
        }
    }
    render(){
        let thisstyle;
        if(this.props.expandir){
            thisstyle = {
                animation: 'expandir 1s forwards'
            };
        }else{
            thisstyle = {
                animation: 'contraer 1s forwards'
            }
        }
        return(
            <div style={thisstyle}  id="menu">
                {this.props.data.permiso!=='reafiliar' &&
                    this.state.buttons.map((button, key)=>{
                        if(this.props.data.rol==='administrador' || !button.adminpage)
                        return (<a key={key} onClick={()=>{this.props.handler(button.route)}}>{button.name}</a>)
                    })
                }
                {this.props.data.permiso==='reafiliar'&&
                    <a onClick={()=>{this.props.handler('/configuracion')}}>Configuración</a>
                }
                <a onClick={()=>{document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; window.location.replace("/")}} className="menu_cerrar">Cerrar sesión</a>
            </div>
        );
    }
}
export default LateralMenu;