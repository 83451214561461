import React, { Component } from 'react';
import burger from '../../assets/img/menu.png';
import logo from '../../assets/img/icono.png';
import admin from '../../assets/img/admin.png';
import gestor from '../../assets/img/gestor.png';
class Header extends Component {
    render() {
        
        return (
            <div id="header">
                <img onClick={()=>{this.props.handler()}} className="menu_logo" src={burger} />
                <span id="header_title">fedamt<img alt="logo" src={logo}/></span>
                {(this.props.data.permiso==='administrador') && (this.props.data.rol==='administrador') && 
                    <img title="Cambiar a vista de gestor" onClick={()=>{this.props.rol()}} className="header_switcher" src={gestor} alt='switcher'/>
                }
                {(this.props.data.permiso==='administrador') && (this.props.data.rol==='gestor') && 
                    <img title="Cambiar a vista de administrador"onClick={()=>{this.props.rol()}} className="header_switcher" src={admin} alt='switcher'/>
                }
                
            </div>
        );
    }

}
export default Header;