import React, { Component } from 'react';
import axios from 'axios';


class NewExam extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disciplinas: [],
            grados:[],
            sexo: 'Masculino',
            licencia: '',
            fecha: '',
            resultado: '1',
            grado: '',
            precio: 0,
            error: false,
            sendMonitores: [],
            monitores: [],
            disciplina: '',
        };

        this.getValue = this.getValue.bind(this);

    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        }, ()=>{this.recalc()});
    }
    checkboxSelect = (key, name) => {
        let sendMonitores = this.state.sendMonitores;
        let monitores = this.state.monitores;
        if (monitores[key].selected) {
            sendMonitores.pop();
        } else {
            sendMonitores.push(monitores[key].id);
        }
        monitores[key].selected = !monitores[key].selected;
        this.setState({
            monitores: monitores,
            sendMonitores: sendMonitores
        });
    }
    recalc=()=>{
        let precio = 0;
        if(this.state.disciplina!==''&&this.state.grado!==''){
            this.state.disciplinas.map((disciplina)=>{
                if(this.state.disciplina==disciplina.id){
                    disciplina.grados.map((grado)=>{
                        if(grado.id==this.state.grado){
                            precio=grado.precio
                        }
                    })
                }
            }) 
        }
        this.setState({
            precio: precio
        })
    }
    send = ()=>{
        if(this.state.licencia==='' || this.state.grado==='' || this.state.fecha==='' || this.state.sendMonitores.length===0){
            window.alert('Faltan datos');
        }else{const url = this.props.server+'/examen';
        axios.post(url, {
            precio: this.state.precio,
            aprobado: this.state.resultado,
            examen: this.state.grado,
            federado: this.state.licencia,
            monitores: this.state.sendMonitores,
            fecha: this.state.fecha

        }, {withCredentials: true}).then((res)=>{
            if(res.data.status===200){
                window.alert("Examen insertado correctamente");
                this.props.handler('newExam', false);
            }
        }).catch(()=>{
            window.alert('Ha ocurrido un error enviando los datos.')
        })}


    }

    render() {
        
        return (
            <div id="column_tab" style={{ top: this.state.altura }}>
                <h1 className="tab_header">Registrar un examen. </h1>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Usuario:</span>
                    <input type="text" className="tab_input" placeholder="Licencia" onChange={this.getValue} name='licencia' />

                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Disciplina:</span>
                    <select className="tab_input" onChange={this.getValue} name='disciplina'>
                        <option hidden defaultChecked>Selecciona una disciplina</option>
                        {
                            this.state.disciplinas.map((disciplina) => {
                                return (<option value={disciplina.id} key={disciplina.id}>{disciplina.nombre}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Grado:</span>
                    <select className="tab_input" onChange={this.getValue} name='grado'>
                        <option hidden defaultChecked>Selecciona un grado</option>
                        {this.state.disciplina !== '' &&
                            this.state.disciplinas.map((disciplina) => {
                                if (disciplina.id == this.state.disciplina) {
                                    return (disciplina.grados.map((grado) => {

                                        return (<option value={grado.id} key={grado.id}>{grado.nombre}</option>
                                        )
                                    }))

                                }
                            })
                        }
                    </select>
                </div>

                <div className="tab_input_container">
                    <span className="tab_input_layout">Fecha:</span>
                    <input type="date" className="tab_input" onChange={this.getValue} name='fecha' />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Resultado:</span>
                    <select className="tab_input" onChange={this.getValue} name='resultado'>
                        <option value='1'>Aprobado</option>
                        <option value='0' >Suspenso</option>
                    </select>
                </div>

                <div className="tab_input_container">
                    <span className="tab_input_layout">Monitores:</span>
                    {
                        this.state.monitores.map((disciplina, key) => {
                            return (
                                <span className={disciplina.selected ? "tab_input_checkbox on" : "tab_input_checkbox"} onClick={() => { this.checkboxSelect(key); }} key={key}>{disciplina.name}</span>
                            );
                        })
                    }

                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Precio a pagar:</span>
                    <span className="tab_input">{this.state.precio} €</span>
                </div>




                <span className="button red left_corner" onClick={() => { this.props.handler('newExam', false); }}>cancelar</span>
                <span onClick={() => { this.send() }} className='button dark right_corner'>Enviar</span>

            </div>
        );
    }
    componentDidMount() {
        let altura = window.pageYOffset + 'px';
        this.setState({
            altura: altura
        })
        let grados = [];
        const url = this.props.server + '/examen';
        axios.put(url, { start: true }, { withCredentials: true }).then((res) => {
            if (res.data.status === 200) {
                let monitores = [];
                res.data.info.monitores.map((monitor)=>{
                    monitores.push({name: monitor.nombre, id: monitor.id, preselected:false})
                });
                this.setState({
                    monitores: monitores
                });
                grados = res.data.info.grados
                let salida = [];
                grados.map((elemento) => {
                    if(this.props.rol==='administrador' || elemento.precio===0){
                        let encontrado = false;
                        salida.map((disciplina) => {
                            if (elemento.disciplinaid === disciplina.id) {
                                encontrado = true
                                disciplina.grados.push({ nombre: elemento.nombre, id: elemento.id, precio:elemento.precio })
                            }
                        });
                        if (!encontrado) {
                            salida.push({
                                nombre: elemento.disciplina,
                                id: elemento.disciplinaid,
                                grados: [
                                    {
                                        nombre: elemento.nombre,
                                        id: elemento.id,
                                        precio: elemento.precio
                                    }]
                            })
                        }
                    }
                    

                })
                this.setState({
                    disciplinas: salida
                });
            }
        }).catch(() => {
            window.alert("Ha ocurrido un error inesperado. Inténtelo de nuevo más tarde.");
        })
    }


}
export default NewExam;
