import React, { Component } from 'react';
import axios from 'axios';

class ModUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            surname: '',
            dni: '',
            nacimiento: '',
            sexo: 'Masculino',
            precio: 0,
            activebefore: '',
            error: false,
            sendDisciplinas: [],
            disciplinas: this.props.data.disciplinas
        };
        this.setDisciplinas();
        this.getValue = this.getValue.bind(this);
    }
    setDisciplinas = ()=>{
        let sendDisciplinas = [];
        let disciplinas = this.state.disciplinas;
        this.props.param.disciplinas.map((disciplina_user)=>{
            sendDisciplinas.push(disciplina_user);
            disciplinas.map((disciplina)=>{
                if(disciplina.name===disciplina_user){
                    disciplina.selected=true
                }
            });
            
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
            
        })

        });
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        },()=>{this.recalc()});
    }
    checkboxSelect = (key)=>{
        let sendDisciplinas = this.state.sendDisciplinas;
        let disciplinas = this.state.disciplinas;
        if(disciplinas[key].selected){
            sendDisciplinas.pop();
        }else{
            sendDisciplinas.push(disciplinas[key].id);
        }
        disciplinas[key].selected = !disciplinas[key].selected;
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
        }, ()=>{this.recalc()});
    }
    
    recalc=()=>{
        let nacimiento = new Date(this.state.nacimiento);
        let hoy =new Date()
        let edad = hoy.getFullYear() - nacimiento.getFullYear();
        let fijo = this.props.data.precios.alta.fijomenor;
        let variable = this.props.data.precios.alta.variablemenor;
        if(edad >= this.props.data.precios.alta.edadcorte){
            fijo = this.props.data.precios.alta.fijomayor;
            variable = this.props.data.precios.alta.variablemayor;
        }
        
        let precio = (variable*this.state.sendDisciplinas.length);
        /**Comprobar si ya tiene algun alta activa para quitar el fijo.*/
        if(this.state.activebefore!==true){
            precio = precio + fijo;
        }
        
        this.setState({
            precio: precio
        }) 
    }
    
    send = ()=>{
        const url = this.props.server+'/federados';
        let params = {
            licencia: this.state.licencia,
            nombre: this.state.name,
            apellidos: this.state.surname,
            dni: this.state.dni,
            sexo: this.state.sexo,
            nacimiento: this.state.nacimiento,
            disciplinas: this.state.sendDisciplinas
        }
        let sede = '';
        let admin = false;
        if(this.props.data.rol==='administrador'){
            sede = this.props.param.idsede;
            admin = true;
        }
        if(this.state.sendDisciplinas.length===0){
            params = {
                licencia: this.state.licencia,
                nombre: this.state.name,
                apellidos: this.state.surname,
                dni: this.state.dni,
                sexo: this.state.sexo,
                nacimiento: this.state.nacimiento,
                sede: sede,
                admin: admin,
                
            }
        }else{
            params = {
                licencia: this.state.licencia,
                nombre: this.state.name,
                apellidos: this.state.surname,
                dni: this.state.dni,
                sexo: this.state.sexo,
                sede: sede,
                admin: admin,
                nacimiento: this.state.nacimiento,
                disciplinas: this.state.sendDisciplinas,
                precio: (this.state.precio/this.state.sendDisciplinas.length)
                
            }
        }
        axios.patch(url, params, {withCredentials: true}).then((res)=>{
            if(res.data.status===200){
                this.props.reload();
                this.props.handler('modUser', false);
            }
        }).catch(()=>{
            window.alert("ha ocurrido un error modificando los datos");
        })
    }
    render() {
        let title = 'Modifica los datos de '+this.props.param.rname+' antes de que sea validado.';
        if(this.props.data.rol==='administrador'){
            title = 'Modificar datos personales de '+this.props.param.rname;
        }
        return (
            <div id="column_tab" style={{top: this.state.altura}}>
                <h1 className="tab_header">{title}</h1>
                
                <div className="tab_input_container">
                    <span className="tab_input_layout">Nombre:</span>
                    <input type='text' onChange={this.getValue} name='name' defaultValue={this.props.param.rname} placeholder='Nombre' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Licencia:</span>
                    <span onChange={this.getValue} className="tab_input" >{this.props.param.licencia}</span>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Apellidos:</span>
                    <input type='text' onChange={this.getValue} name='surname' defaultValue={this.props.param.rsurname}  placeholder='Apellidos' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">DNI:</span>
                    <input type='text' onChange={this.getValue} name='dni' defaultValue={this.props.param.dni}  placeholder='DNI' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Nacimiento:</span>
                    <input type='date' onChange={this.getValue} defaultValue={this.props.param.nacimiento} name='nacimiento' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Sexo:</span>
                    <select className="tab_input" onChange={this.getValue} name='sexo'>
                        <option>Masculino</option>
                        <option>Femenino</option>
                    </select>
                </div>
                {(this.props.data.rol!=='administrador') &&
                    <div className="tab_input_container">
                    <span className="tab_input_layout">Disciplinas:</span>
                    {
                        this.state.disciplinas.map((disciplina, key) => {
                            return (
                                <span className={disciplina.selected?"tab_input_checkbox on":"tab_input_checkbox"} onClick={()=>{this.checkboxSelect(key);}} key={key}>{disciplina.name}</span>
                            );
                        })
                    }

                    </div>
                }
                {(this.props.data.rol!=='administrador') &&
                    <div className="tab_input_container">
                    <span className="tab_input_layout">Precio a pagar:</span>
                    <span className="tab_input">{this.state.precio} €</span>
                </div>
                }
                




                <span className="button red left_corner" onClick={() => { this.props.handler('modUser', false); }}>cancelar</span>
                <span onClick={() => { this.send() }} className='button dark right_corner'>Modificar</span>

            </div>
        );
    }
    componentDidMount(){
        let altura = window.pageYOffset + 'px';
        this.setState({
            altura: altura,
            licencia: this.props.param.licencia,
            name: this.props.param.rname,
            surname: this.props.param.rsurname,
            dni: this.props.param.dni,
            sexo: this.props.param.sexo,
            nacimiento: this.props.param.nacimiento,
            activebefore: this.props.param.activebefore
        })
    }

}
export default ModUser;