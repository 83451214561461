import React, { Component } from 'react';
import axios from 'axios';

class Reafiliar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preciosede: 0,
            preciofederado: 0,
            preciomonitor: 0,
            error: false,
            sendDisciplinas: [],
            disciplinas: []
        };
    }

    checkboxSelect = (key) => {
        let sendDisciplinas = this.state.sendDisciplinas;
        let disciplinas = this.state.disciplinas;
        if (disciplinas[key].selected) {
            sendDisciplinas.pop();
        } else {
            sendDisciplinas.push(disciplinas[key].id);
        }
        disciplinas[key].selected = !disciplinas[key].selected;
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
        },()=>{this.recalc()} );
    }
    recalc = () => {
        let preciosede = 0;
        let preciofederado = 0;
        let preciomonitor = 0;
        let fijo = 0;
        let variable = 0;
        /*Calculo del precio de sede*/
        fijo = this.props.data.precios.sede.fijo;
        variable = this.props.data.precios.sede.disciplina * this.state.sendDisciplinas.length;
        preciosede = fijo + variable;
        /*Calculo del precio de federado*/
        fijo = this.props.data.precios.alta.fijomayor;
        variable = this.props.data.precios.alta.variablemayor;
        preciofederado = fijo + variable*this.state.sendDisciplinas.length;
        /*Calculo del precio de monitor*/
        preciomonitor = this.state.sendDisciplinas.length*this.props.data.precios.sede.monitor;
        this.setState({
            preciofederado: preciofederado,
            preciomonitor: preciomonitor,
            preciosede: preciosede
        })
    }
    send = () => {

        if (this.state.sendDisciplinas.length === 0 ) {
            window.alert('¡Faltan datos!');
        } else {
            /**Primero reafiliamos la sede*/
            let data = {
                disciplinas: this.state.sendDisciplinas,
                precio: this.state.preciosede / this.state.sendDisciplinas.length,
                preciofederado: (this.state.preciofederado / this.state.sendDisciplinas.length),
                preciomonitor: (this.state.preciomonitor / this.state.sendDisciplinas.length),
            }
            let url = this.props.server + '/sedes';
            axios.post(url, data, { withCredentials: true }).then((res) => {
                if (res.data.status === 200) {
                    this.props.handler('reafiliar', false)
                    window.alert('Sede registrada, pendiente de validación.');
                }
            }).catch(() => {
                window.alert("La sede no se ha creado correctamente.");
            })
        }
    }
 
    render() {

        return (
            <div id="column_tab" style={{ top: this.state.altura }}>
                <h1 className="tab_header">Reafiliar Sede. </h1>
               
                <div className="tab_input_container">
                    <span className="tab_input_layout">Selecciona las disciplinas:</span>
                    {
                        this.state.disciplinas.map((disciplina, key) => {
                            return (
                                <span className={disciplina.selected ? "tab_input_checkbox on" : "tab_input_checkbox"} onClick={() => { this.checkboxSelect(key); }} key={key}>{disciplina.name}</span>
                            );
                        })
                    }

                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Precio a pagar: (Incluye alta del federado, y alta como monitor)</span>
                    <span className="tab_input">{this.state.preciosede + this.state.preciofederado + this.state.preciomonitor}€</span>
                </div>




                <span className="button red left_corner" onClick={() => { this.props.handler('reafiliar', false) }}>cancelar</span>
                <span onClick={() => { this.send() }} className='button dark right_corner'>Reafiliar</span>
            </div>
        );
    }
    load = () => {
        const url = this.props.server + '/disciplina';
        axios.put(url, { admin: true }, { withCredentials: true }).then((res) => {
            if (res.data.status === 200) {
                let disciplinas = [];
                res.data.disciplinas.map((disciplina) => {
                    disciplinas.push({ name: disciplina.nombre, id: disciplina.id, selected: false })
                })
                this.setState({
                    disciplinas: disciplinas
                })
            }
        }).catch(() => {
            window.alert('Error al cargar las disciplinas')
        });
    }
    componentDidMount() {
        let altura = window.pageYOffset + 'px';
        this.setState({
            altura: altura
        })
        this.load();
    }

}
export default Reafiliar;