import React, { Component } from 'react';
import axios from 'axios';

class ModMon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            precio: 0,
            sendDisciplinas: [],
            disciplinas: []
        };
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    checkboxSelect = (key)=>{
        let sendDisciplinas = this.state.sendDisciplinas;
        let disciplinas = this.state.disciplinas;
        if(disciplinas[key].selected){
            sendDisciplinas.pop();
        }else{
            sendDisciplinas.push(disciplinas[key].id);
        }
        disciplinas[key].selected = !disciplinas[key].selected;
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
        }, ()=>{this.recalc()});
    }
    send = () => {
        if (this.state.sendDisciplinas.length === 0) {
            window.alert('Faltan datos por completar.')
        } else {
            let data = {
                disciplinas: this.state.sendDisciplinas,
                licencia: this.props.param.licencia,
                precio: (this.state.precio/this.state.sendDisciplinas.length)
            }
            if(this.props.rol==='administrador'){
                data.admin = true;
                data.sede = this.props.param.idsede
            }
            const url = this.props.server + '/monitor';
            axios.patch(url, data, { withCredentials: true }).then((res) => {
                if (res.data.status === 200) {
                    window.alert('Datos actualizados con éxito');
                    this.props.reload();
                    this.props.handler('modMon', false);
                }
            }).catch(() => {
                window.alert('Ha ocurrido un error creando el monitor.');
            })
        }

    }
    recalc = ()=>{
        let cost = this.props.data.precios.sede.monitor;
        let precio = cost * this.state.sendDisciplinas.length;
        this.setState({
            precio: precio
        });
    }
    render() {
        return (
            <div id="column_tab" style={{top: this.state.altura}}>
                <h1 className="tab_header">Modificar disciplinas. </h1>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Disciplinas:</span>
                    {
                        this.state.disciplinas.map((disciplina, key) => {
                            return (
                                <span className={disciplina.selected ? "tab_input_checkbox on" : "tab_input_checkbox"} onClick={() => { this.checkboxSelect(key); }} key={key}>{disciplina.name}</span>
                            );
                        })
                    }
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Precio a pagar:</span>
                    <span className="tab_input">{this.state.precio} €</span>
                </div>
                <span className="button red left_corner" onClick={() => { this.props.handler('modMon', false); }}>cancelar</span>
                <span onClick={() => { this.send() }} className='button dark right_corner'>Aplicar cambios</span>

            </div>
        );
    }
    componentDidMount() {
        let altura = window.pageYOffset + 'px';
        let disciplinas = [];
        if(this.props.param.moncert){
            this.props.param.moncert.map((certificado) => {
                disciplinas.push({ name: certificado.nombre, id: certificado.iddisciplina, selected: false })
            })
        }
        this.setState({
            altura: altura,
            disciplinas: disciplinas
        })
    }

}
export default ModMon;