import React, { Component } from 'react';
import axios from 'axios';

class ClubCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            desplegado: false
        }
    }
    change = () => {
        this.setState({
            desplegado: !this.state.desplegado
        });
    }
    validate = ()=>{
        const url = this.props.server + '/sedes';
        axios.patch(url, {validate:true, admin:true,licencia: this.props.club.licencia}, {withCredentials:true}).then((res)=>{
            if(res.data.status===200)
            {
                this.change();
                this.props.reload();
            }
        }).catch(()=>{
            window.alert('Error al validar');
        })
    }
    render() {

        return (
            <div className="tarjeta_s_container">
                <div className="tarjeta_s">
                    <span className="tarjeta_s_licencia"><b>{this.props.club.licencia}</b></span>
                    <span className="tarjeta_s_nombre">{this.props.club.name}</span>
                    <a onClick={this.change} className="button tarjeta_s_button">{(this.state.desplegado?'ocultar':'ver')}</a>
                </div>
                <div className={"tarjeta_s_footer spaced"+(this.state.desplegado?" see":" unsee")}>
                {/*<span className='tarjeta_s_layer'><b>Precio: </b>{this.props.club.precio}</span>*/}
                <span className='tarjeta_s_layer'><b>Correo: </b>{this.props.club.correo}</span>
                <span className='tarjeta_s_layer'><b>Dirección: </b>{this.props.club.direccion}</span>
                    <span className='tarjeta_s_layer'><b>Monitores: </b>{this.props.club.monitores.map((monitor, key)=>{
                        if(key==(this.props.club.monitores.length-1)){
                            return(monitor.name)
                        }else{
                            return(monitor.name+', ')
                        }
                        
                        })}</span>
                    <span className='tarjeta_s_layer'><b>Disciplinas: </b>{this.props.club.disciplinas.map((disciplina, key)=>{
                        if(key==(this.props.club.disciplinas.length-1)){
                            return(disciplina.nombre)
                        }else{
                            return(disciplina.nombre+', ')
                        }
                        
                        })}</span>
                    {this.props.type === 'validado' &&
                        <span onClick={() => { this.props.modifyHandler('modClub', true, this.props.club ); }} className='button dark right_corner'>Modificar</span>
                    }
                   
                    {this.props.type != 'validado' &&
                        <span onClick={()=>{this.validate()}} className='button dark right_corner'>Validar</span>
                    }
                </div>
            </div>

        );
    }
}

export default ClubCard;