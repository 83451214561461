import React, { Component } from 'react';
import mas from '../../assets/img/mas.png';
import AddUser from '../tabs/AddUser';
import ModUser from '../tabs/ModUser';
import SolForm from '../tabs/SolForm';
import UserCard from '../UserCard';
import axios from 'axios';

class Federados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchingkey: '',
            notsearching: true,
            loading: true,
            users: [

            ],
            renderUsers: [],
            validRenderUsers: [],
            addUser: { status: false, param: '' },
            modUser: { status: false, param: '' },
            solForm: { status: false, param: '' }
        }
        this.getValue = this.getValue.bind(this);
    }
    changeView = (window_tab, state, param) => {
        this.setState({
            [window_tab]: { status: state, param: param }
        })
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.search(event.target.value)
    }
    search = (cadena) => {
        
        let list = [];
        
        cadena = cadena.toLowerCase();
        
        


        this.state.users.map((user) => {
            let name = user.name.toLowerCase();
            let licencia = user.licencia;
            let dni = user.dni.toLowerCase();
            if (user.valido) {
                if (name.indexOf(cadena) !== -1 || licencia.toString()===cadena || dni === cadena) {
                    list.push(user)
                }

            }
        });
        this.setState({
            validRenderUsers: list,
            notsearching: (cadena === '' ? true : false)
        });
    }
    validate = (licencia, sede) =>{
        const url = this.props.server + '/federados';
        axios.patch(url, {
            admin: true,
            valido: true,
            licencia: licencia,
            sede: sede
        }, {withCredentials: true})
        .then((res)=>{
            if(res.data.status===200){
                this.load();
            }
        })
        .catch(()=>{
            this.setState({
                pageError: { state: true, message: 'Error validando' }
            })
        })
    }
    delete = (licencia, sede) =>{
        const url = this.props.server + '/federados';
        let parameters;
        if(this.props.data.rol==='administrador'){
            parameters = {
                admin: true,
                licencia: licencia,
                sede: sede
            }
        }else{
            parameters = {
                licencia: licencia
            }
        }
        axios.delete(url,{
            data: parameters,
            withCredentials:true
        })
        .then((res)=>{
            if(res.data.status===200){
                this.load();
            }
        })
        .catch(()=>{
            this.setState({
                pageError: { state: true, message: 'Error validando' }
            })
        })
    }

    render() {
        if(this.state.loading){
            return(<div className="content_window"></div>)
        }else{
            return (
                <div className="content_window">
                    <div className="center_column">
                        {(this.state.addUser.status) &&
                            <AddUser
                                handler={this.changeView}
                                reload={this.load}
                                data={this.props.data}
                                server={this.props.server}
                            />
                        }
                        {(this.state.solForm.status) &&
                            <SolForm
                                handler={this.changeView}
                                param={this.state.solForm.param}
                                data={this.props.data}
                            />
                        }
                        {(this.state.modUser.status) &&
                            <ModUser
                                handler={this.changeView}
                                param={this.state.modUser.param}
                                reload={this.load}
                                data={this.props.data}
                                server={this.props.server}
                            />
                        }
                        <div className="full_width floating_left">
                            <input type='text' onChange={this.getValue} name='searchingkey' placeholder='Buscar por licencia, nombre o DNI...' className="search_input floating_left" />
                        </div>
                        {this.state.notsearching &&
                            <h3 className="spacer full_width floating_left">Pendientes de validación: {this.state.renderUsers.length}</h3>
                        }
                        {this.state.notsearching &&
                            this.state.renderUsers.map((user, key) => {
                                return (
                                    <UserCard
                                        key={key}
                                        user={user}
                                        modifyHandler={this.changeView}
                                        rol={this.props.data.rol}
                                        type='pendiente'
                                        validate={this.validate}
                                        delete={this.delete}
                                    />
                                )
                            })
                        }
                        <h3 className="spacer floating_left">{this.state.notsearching ? 'Validados' : 'Resultado de búsqueda'}: {this.state.validRenderUsers.length}</h3>
                        {
                            this.state.validRenderUsers.map((user, key) => {
                                if (user.valido) {
                                    return (
                                        <UserCard
                                            key={key}
                                            user={user}
                                            rol={this.props.data.rol}
                                            modifyHandler={this.changeView}
                                            type='validado'
                                        />
                                    )
                                }
                            })
                        }
                    </div>
                    {this.props.data.rol === 'gestor' &&
                        <img onClick={() => { this.changeView('addUser', true) }} className="float_button" alt="Crear nuevo usuario" title="Crear nuevo usuario" src={mas} />
                    }
                </div>
            );
        }
        
    }
    load = ()=>{
        /**En funcion del rol se cargan los usuarios */
        let admin = this.props.data.rol === 'administrador' ? true : false;
        const url = this.props.server + '/federados';
        axios.put(url, {
            admin: admin,
        }, { withCredentials: true }).then((res) => {
            if (res.data.status === 200) {
                let Receivedusers = res.data.users;
                let validusers = [];
                let users = [];
                Receivedusers.map((user) => {
                    if (user.valido) {
                        validusers.push(user);
                    } else {
                        users.push(user);
                    }
                })
                this.setState({
                    users: Receivedusers,
                    renderUsers: users,
                    validRenderUsers: validusers,
                    loading: false
                })
            } else {

                this.setState({
                    pageError: { state: true, message: "Error cargando usuarios" }
                })
            }
        })
            .catch((err) => {

                this.setState({
                    pageError: { state: true, message: 'Error cargando usuarios' }
                })
            })
    }
    componentDidMount() {
        this.load();
    }
}

export default Federados;