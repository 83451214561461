import React, { Component } from 'react';
import axios from 'axios';

class ModClub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.param.name,
            correo: this.props.param.correo,
            dirección: this.props.param.direccion,
            precio: 0,
            error: false,
            sendDisciplinas: [],
            disciplinas: [
                {name: 'Karatedo',selected: false},
                {name: 'Shin Ki Tai',selected: false},
                {name: 'Kubotan',selected: false},
                {name: 'Trisistema',selected: false},
                {name: 'Hapkido',selected: false},
                {name: 'Aikido',selected: false},
                {name: 'Full Contact',selected: false},
                {name: 'Defensa Personal',selected: false},
                {name: 'Eiguido',selected: false},
                {name: 'Kick Boxing',selected: false},
                {name: 'Muay Tai',selected: false},
                {name: 'Iaido',selected: false},
                {name: 'Kenpo Karate',selected: false},
                {name: 'Jiu-jitsu',selected: false},
                {name: 'Kung fu',selected: false}
            ]
        };
        this.setDisciplinas();
        this.getValue = this.getValue.bind(this);
    }
    setDisciplinas = ()=>{
        let sendDisciplinas = [];
        let disciplinas = this.state.disciplinas;
        this.props.param.disciplinas.map((disciplina_user)=>{
            sendDisciplinas.push(disciplina_user);
            disciplinas.map((disciplina)=>{
                if(disciplina.name===disciplina_user){
                    disciplina.selected=true
                }
            });
            
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
            
        })

        });
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    checkboxSelect = (key)=>{
        let sendDisciplinas = this.state.sendDisciplinas;
        let disciplinas = this.state.disciplinas;
        if(disciplinas[key].selected){
            sendDisciplinas.pop();
        }else{
            sendDisciplinas.push(disciplinas[key].id);
        }
        disciplinas[key].selected = !disciplinas[key].selected;
        this.setState({
            sendDisciplinas: sendDisciplinas
        }, ()=>{this.recalc()});
    }
    recalc=()=>{
        const precio = this.props.data.precios.sede.disciplina;
        this.setState({
            precio: (precio*this.state.sendDisciplinas.length)
        })
    }
    send=()=>{
        const url = this.props.server + '/sedes';

        if(this.state.name==='' || this.state.correo==='' || this.state.direccion ===''){
            window.alert('Faltan datos por rellenar');
        }else{
            let data = {
                admin: true,
                nombre : this.state.name,
                correo: this.state.correo,
                direccion: this.state.dirección,
                licencia: this.props.param.licencia
            }
            if(this.state.sendDisciplinas.length!==0){
                data.disciplinas = this.state.sendDisciplinas;
                data.precio = (this.state.precio/this.state.sendDisciplinas.length)
            }
            axios.patch(url,data,{withCredentials: true}).then((res)=>{
                if(res.data.status===200){
                    this.props.reload();
                    this.props.handler('modClub', false);
                }
            }).catch(()=>{
                window.alert('Ha ocurrido un error modificando la información.')
            })
        }
    }
    render() {
        
        let correo = this.props.param.correo;
        return (
            <div id="column_tab" style={{top: this.state.altura}}>
                <h1 className="tab_header">Modificar datos de la sede. </h1>
                
                <div className="tab_input_container">
                    <span className="tab_input_layout">Nombre:</span>
                    <input type='text' onChange={this.getValue} name='name'defaultValue={this.props.param.name} placeholder='Nombre' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Licencia:</span>
                    <span onChange={this.getValue} className="tab_input" >{this.props.param.licencia}</span>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Correo:</span>
                    <input type='text' onChange={this.getValue} name='correo'defaultValue={correo} placeholder='Email' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Dirección:</span>
                    <input type='text' onChange={this.getValue} name='direccion'defaultValue={this.props.param.direccion} placeholder='Dirección' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Disciplinas:</span>
                    {
                        this.state.disciplinas.map((disciplina, key) => {
                            return (
                                <span className={disciplina.selected?"tab_input_checkbox on":"tab_input_checkbox"} onClick={()=>{this.checkboxSelect(key);}} key={key}>{disciplina.nombre}</span>
                            );
                        })
                    }

                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Precio:</span>
                    <span className="tab_input" >{this.state.precio}€</span>
                </div>




                <span className="button red left_corner" onClick={() => { this.props.handler('modClub', false); }}>cancelar</span>
                <span onClick={() => { this.send() }} className='button dark right_corner'>Aplicar cambios</span>

            </div>
        );
    }
    load = ()=>{
        const url = this.props.server + '/disciplina';
        axios.put(url,{admin: true}, {withCredentials: true}).then((res)=>{
            if(res.data.status===200){
                this.setState({
                    disciplinas: res.data.disciplinas
                })
            }
        }).catch(()=>{
            window.alert('Error al cargar las disciplinas')
        });
    }
    componentDidMount(){
        this.load();
        let altura = window.pageYOffset + 'px';
        this.setState({
            altura: altura
        })
    }

}
export default ModClub;