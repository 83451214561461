import React, { Component } from 'react';


class AddJuez extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            surname: '',
            dni: '',
            nacimiento: '',
            sexo: 'Masculino',
            error: false,
            sendDisciplinas: [],
            disciplinas: [
                {name: 'Karatedo',selected: false, id: 123},
                {name: 'Shin Ki Tai',selected: false, id: 123},
                {name: 'Kubotan',selected: false, id: 123},
                {name: 'Trisistema',selected: false, id: 123},
                {name: 'Hapkido',selected: false, id: 123},
                {name: 'Aikido',selected: false, id: 123},
                {name: 'Full Contact',selected: false, id: 123},
                {name: 'Defensa Personal',selected: false, id: 123},
                {name: 'Eiguido',selected: false, id: 123},
                {name: 'Kick Boxing',selected: false, id: 123},
                {name: 'Muay Tai',selected: false, id: 123},
                {name: 'Iaido',selected: false, id: 123},
                {name: 'Kenpo Karate',selected: false, id: 123},
                {name: 'Jiu-jitsu',selected: false, id: 123},
                {name: 'Kung fu',selected: false, id: 123}
            ]
        };
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    checkboxSelect = (key)=>{
        let sendDisciplinas = this.state.sendDisciplinas;
        let disciplinas = this.state.disciplinas;
        if(disciplinas[key].selected){
            sendDisciplinas.pop();
        }else{
            sendDisciplinas.push(disciplinas[key].id);
        }
        disciplinas[key].selected = !disciplinas[key].selected;
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
        });
    }
    render() {
        let altura=window.pageYOffset+'px';
        return (
            <div id="column_tab" style={{top: altura}}>
                <h1 className="tab_header">Añadir juez. </h1>
                
                <div className="tab_input_container">
                    <span className="tab_input_layout">Licencia:</span>
                    <input type='text' onChange={this.getValue} name='licencia' placeholder='Licencia' className="tab_input" />
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Tipo:</span>
                    <select className="tab_input" onChange={this.getValue} name='tipo'>
                        <option defaultValue hidden>Selecciona una opción</option>
                        <option>Arbitro Provincial</option>
                        <option>Arbitro Regional</option>
                        <option>Arbitro Nacional</option>
                        <option>Arbitro Internacional</option>
                        <option>Juez Provincial</option>
                        <option>Juez Regional</option>
                        <option>Juez Nacional</option>
                        <option>Juez Internacional</option>
                    </select>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Modalidad:</span>
                    <select className="tab_input" onChange={this.getValue} name='modalidad'>
                        <option defaultValue hidden>Selecciona una opción</option>
                        <option>Kumite</option>
                        <option>Katas</option>
                        <option>De Técnicas y Exhibición de Artes Marciales</option>
                        <option>De Grados</option>
                    </select>
                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">Disciplina:</span>
                    <select className="tab_input" onChange={this.getValue} name='disciplina'>
                        <option defaultValue hidden>Selecciona una opción</option>
                        {this.state.disciplinas.map((disciplina)=>{
                            return(<option>{disciplina.name}</option>)
                        })}
                    </select>
                </div>
                

                <span className="button red left_corner" onClick={() => { this.props.handler('addJuez', false); }}>cancelar</span>
                <span onClick={() => { console.log(this.state); }} className='button dark right_corner'>Añadir</span>

            </div>
        );
    }

}
export default AddJuez;