import React, { Component } from 'react';
import axios from 'axios';

class UserCard extends Component {
    constructor(props) {
        super(props);
        let precio = 0;
        if(this.props.type==='validado' || this.props.type==='pendiente'){
            this.props.user.disciplinas.map((disciplina)=>{ 
                precio = precio+disciplina.precio
            })
        }
        
        this.state = {
            desplegado: false,
            precio: precio
        }
    }
    change = () => {
        this.setState({
            desplegado: !this.state.desplegado
        });
    }
    deleteMon=()=>{
        const federado = this.props.user.licencia;
        const url=this.props.server+'/monitor';
        let parameters = {
            federado: federado
        };
        if(this.props.rol==='administrador'){
          parameters.admin = true;
          parameters.sede = this.props.user.idsede;  
        }
        axios.delete(url, {
            data: parameters,
            withCredentials:true
        }).then((res)=>{
            if(res.data.status===200){
                this.change();
                this.props.reload();
            }
        }).catch(()=>{
            window.alert('Ha ocurrido un error.');
        });
    }
    render() {
        
        return (
            <div className="tarjeta_s_container">
                <div className="tarjeta_s">
                    <span className="tarjeta_s_licencia"><b>{this.props.user.licencia}</b></span>
                    <span className="tarjeta_s_nombre">{this.props.user.name}</span>
                    <a onClick={this.change} className="button tarjeta_s_button">{(this.state.desplegado?'ocultar':'ver')}</a>
                </div>
                <div className={"tarjeta_s_footer"+(this.props.type=='default'?'':' spaced')+(this.state.desplegado?" see":" unsee")}>
                    {this.props.rol==='administrador' &&
                        <span className='tarjeta_s_layer'><b>Sede: </b>{this.props.user.sede}</span>
                    }
                    {this.props.rol==='administrador' && this.props.type !== 'monitor' &&
                        <span className='tarjeta_s_layer'><b>Precio: </b>{this.state.precio}</span>
                    }
                    {this.props.type!=='monitor' &&
                        <span className='tarjeta_s_layer'><b>DNI: </b>{this.props.user.dni}</span>
                    }
                    {(this.props.type=='monitor')&&
                         <span className='tarjeta_s_layer'><b>Tipo: </b>{this.props.user.tipo}</span>  
                    }
                    {(this.props.type!=='monitor')&&
                        <span className='tarjeta_s_layer'><b>Fecha de nacimiento: </b>{this.props.user.nacimiento}</span>
                    }
                    
                    {(this.props.type=='monitor')&&
                         <span className='tarjeta_s_layer'><b>Correo: </b>{this.props.user.correo}</span>  
                    }
                    {(this.props.type=='certificado')&&this.props.user.moncert&&
                         <span className='tarjeta_s_layer'><b>Certificado en: </b>{this.props.user.moncert.map((disciplina, key)=>{
                        if(key==(this.props.user.moncert.length-1)){
                            return(disciplina.nombre)
                        }else{
                            return(disciplina.nombre+', ')
                        }
                        
                        })}</span>  
                    }
                    {(this.props.type=='monitor')&&this.props.user.mondisc&&
                         <span className='tarjeta_s_layer'><b>Monitor en: </b>{this.props.user.mondisc.map((disciplina, key)=>{
                        if(key==(this.props.user.mondisc.length-1)){
                            return(disciplina.nombre)
                        }else{
                            return(disciplina.nombre+', ')
                        }
                        
                        })}</span>  
                    }
                    {(this.props.type!=='monitor')&&(this.props.type!=='certificado')&&
                         <span className='tarjeta_s_layer'><b>Disciplinas: </b>{this.props.user.disciplinas.map((disciplina, key)=>{
                            if(key==(this.props.user.disciplinas.length-1)){
                                return(disciplina.nombre)
                            }else{
                                return(disciplina.nombre+', ')
                            }  
                        })}</span>  
                    }
                    {(this.props.type=='pendiente')&& this.props.rol ==='gestor' &&
                        <React.Fragment>
                            <span onClick={() => { this.props.modifyHandler('modUser', true, this.props.user)}} className='button dark right_corner'>Modificar</span>
                            <span onClick={() => { if (window.confirm("¿Seguro que quieres eliminar la inscripción de "+this.props.user.name+"?")) {this.props.delete(this.props.user.licencia ,0) } }} className='button red left_corner'>Eliminar</span>
                        </React.Fragment>  
                    }
                    {(this.props.type=='pendiente')&& this.props.rol ==='administrador' &&
                        <React.Fragment>
                            <span onClick={() => { this.props.validate(this.props.user.licencia, this.props.user.idsede) }} className='button dark right_corner'>Validar</span>
                            <span onClick={() => { if (window.confirm("¿Seguro que quieres eliminar la inscripción de "+this.props.user.name+"?")) {this.props.delete(this.props.user.licencia ,this.props.user.idsede) } }} className='button red left_corner'>Eliminar</span>
                        </React.Fragment>  
                    }
                    {(this.props.type=='validado')&& this.props.rol ==='gestor' && 
                            <span onClick={() => { this.props.modifyHandler('solForm', true, this.props.user) }} className='button dark right_corner'>Solicitar Formación</span>
                    }
                    {(this.props.type=='validado')&& this.props.rol ==='administrador' &&
                        <span onClick={() => { this.props.modifyHandler('modUser', true, this.props.user) }} className='button dark right_corner'>Modificar</span>     
                    }
                    {(this.props.type=='certificado')&&   
                            <span onClick={() => { this.props.modifyHandler('signMon', true, this.props.user) }} className='button dark right_corner'>Hacer monitor</span>
                    }
                    {(this.props.type==='monitor')&&
                        <React.Fragment>
                             <span onClick={() => { this.deleteMon() }} className='button dark right_corner'>Dar de baja</span>
                             <span onClick={() => { this.props.modifyHandler('modMon', true, this.props.user) }} className='button dark left_corner'>Añadir disciplina</span>
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

export default UserCard;