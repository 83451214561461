import React, { Component } from 'react';
import axios from 'axios';

class SignMon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            correo: '',
            error: false,
            precio: 0,
            sendDisciplinas: [],
            disciplinas: [
                { name: 'Karatedo', selected: false },
                { name: 'Shin Ki Tai', selected: false },
                { name: 'Kubotan', selected: false },

            ]
        };
        this.getValue = this.getValue.bind(this);
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    checkboxSelect = (key) => {
        let sendDisciplinas = this.state.sendDisciplinas;
        let disciplinas = this.state.disciplinas;
        if (disciplinas[key].selected) {
            sendDisciplinas.pop();
        } else {
            sendDisciplinas.push(disciplinas[key].id);
        }
        disciplinas[key].selected = !disciplinas[key].selected;
        this.setState({
            disciplinas: disciplinas,
            sendDisciplinas: sendDisciplinas
        }, ()=>{this.recalc()});
    }
    create = () => {
        if (this.state.precio === '' || this.state.correo === '' || this.state.sendDisciplinas.length === 0) {
            window.alert('Faltan datos por completar.')
        } else {
            let data = {
                precio: (this.state.precio/this.state.sendDisciplinas.length),
                correo: this.state.correo,
                disciplinas: this.state.sendDisciplinas,
                federado: this.props.param.licencia
            }
            const url = this.props.server + '/monitor';
            axios.post(url, data, { withCredentials: true }).then((res) => {
                if (res.data.status === 200) {
                    window.alert('Creado con éxito. Puedes iniciar sesión usando el correo: ' + data.correo + ' y la contraseña: fedamt');
                    this.props.reload();
                    this.props.handler('signMon', false);
                }else if(res.data.status ===201){
                    window.alert('El correo introducido ya existe');
                }
            }).catch(() => {
                window.alert('Ha ocurrido un error creando el monitor.');
            })
        }
    }
    recalc = ()=>{
        let cost = this.props.data.precios.sede.monitor;
        let precio = cost * this.state.sendDisciplinas.length;
        this.setState({
            precio: precio
        });
    }
    render() {

        let disabled = this.state.disabled ? 'disabled' : '';
        return (
            <div id="column_tab" style={{ top: this.state.altura }}>
                <h1 className="tab_header">Dar de alta como monitor a {this.props.param.name}</h1>

                <div className="tab_input_container">
                    <span className="tab_input_layout">Disciplinas:</span>
                    {
                        this.state.disciplinas.map((disciplina, key) => {
                            return (
                                <span className={disciplina.selected ? "tab_input_checkbox on" : "tab_input_checkbox"} onClick={() => { this.checkboxSelect(key); }} key={key}>{disciplina.name}</span>
                            );
                        })
                    }

                </div>
                <div className="tab_input_container">
                    <span className="tab_input_layout">E-mail:</span>
                    <input type='text' onChange={this.getValue} name='correo' placeholder='E-mail' className="tab_input" />
                </div>

                <div className="tab_input_container">
                    <span className="tab_input_layout">Precio a pagar:</span>
                    <span className="tab_input">{this.state.precio} €</span>
                </div>




                <span className="button red left_corner" onClick={() => { this.props.handler('signMon', false); }}>cancelar</span>
                <span onClick={() => { this.create() }} className='button dark right_corner'>Registrar</span>

            </div>
        );
    }
    componentDidMount() {
        let altura = window.pageYOffset + 'px';
        let disciplinas = [];
        this.props.param.moncert.map((certificado) => {
            console.log(certificado)
            disciplinas.push({ name: certificado.nombre, id: certificado.iddisciplina, selected: false })
        })
        this.setState({
            altura: altura,
            disciplinas: disciplinas
        })
    }

}
export default SignMon;